<template>
  <div class="partial-render-dialog-container">
    <div class="partial-render-dialog-header">
      <v-btn
        icon="mdi-close"
        variant="text"
        @click="openCloseAlert"
      />
    </div>
    <div class="partial-render-dialog-content">
      <div class="drawing-tool-section">
        <DrawingTool
          :can-undo="drawingBoardRef?.canUndo || false"
          :can-redo="drawingBoardRef?.canRedo || false"
          :has-content="drawingBoardRef?.hasContent || false"
          :is-mobile="isMobile"
          @undo="undo"
          @redo="redo"
          @clear="clearCanvas"
        />
        <v-btn
          v-if="!isMobile"
          variant="flat"
          class="render-btn"
          :disabled="!(drawingBoardRef?.hasContent || false)"
          @click="applyDrawings"
        >
          レンダリングへ進む
        </v-btn>
      </div>

      <div class="drawing-board-section">
        <TargetImages v-if="hasTargetImages" />
        <DrawingBoard ref="drawingBoardRef" />
      </div>

      <div class="mobile-render-btn-section">
        <v-btn
          v-if="isMobile"
          variant="flat"
          class="render-btn"
          :disabled="!(drawingBoardRef?.hasContent || false)"
          @click="applyDrawings"
        >
          レンダリングへ進む
        </v-btn>
      </div>
    </div>

    <CloseAlert
      :is-open="isOpenCloseAlert"
      @close="closeCloseAlert"
      @clear="closePartialRenderDialog"
    />
  </div>
</template>

<script lang="ts" setup>
import type { ComponentPublicInstance } from 'vue'
import DrawingBoard from '@/components/generate/drawing-board/drawing-board.vue'

const { id } = defineProps<{ id: string }>()
const drawingBoardRef = ref<ComponentPublicInstance<{
  clearCanvas: () => void
  initCanvas: () => void
  applyDrawings: () => void
  undo: () => void
  redo: () => void
  hasContent: boolean
  canUndo: boolean
  canRedo: boolean
}> | null>(null)

const dialogStore = useDialogStore()
const canvasStore = useCanvasStore()
const { targetImages } = storeToRefs(canvasStore)
const hasTargetImages = computed(() => targetImages.value.before || targetImages.value.after)
const uploadImageStore = useUploadImageStore()
const { imageUrl } = storeToRefs(uploadImageStore)

const isOpenCloseAlert = ref(false)

const { isMobile, observe } = useResizeObserver()

const undo = () => {
  drawingBoardRef.value?.undo()
}

const redo = () => {
  drawingBoardRef.value?.redo()
}

const clearCanvas = () => {
  drawingBoardRef.value?.clearCanvas()
}

const initCanvas = () => {
  drawingBoardRef.value?.initCanvas()
  canvasStore.clearMask()
}

const applyDrawings = () => {
  drawingBoardRef.value?.applyDrawings()
  dialogStore.closeDialog(id)

  if (imageUrl.value) {
    uploadImageStore.clearImageSrcOnUpload()
  }
}

const openCloseAlert = () => {
  isOpenCloseAlert.value = true
}

const closeCloseAlert = () => {
  isOpenCloseAlert.value = false
}

const closePartialRenderDialog = () => {
  closeCloseAlert()
  dialogStore.closeDialog(id)
  initCanvas()
}

onMounted(() => {
  observe()
})
</script>

<style lang="scss" scoped>
.partial-render-dialog-container {
  width: 100vw;
  height: 100vh;
  padding-top: 48px;
  display: flex;
  flex-direction: column;

  .partial-render-dialog-header {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    background: transparent;
  }

  .partial-render-dialog-content {
    background-color: #202835;
    padding: 16px;
    width: 100%;
    flex: 1;
    min-height: 0;
    display: flex;
    gap: 16px;
    border-radius: 1rem;

    @media (max-width: 768px) {
      flex-direction: column;
      padding: 12px;
    }

    .drawing-tool-section {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-between;
      overflow-y: scroll;

      @media (max-width: 768px) {
        width: 100%;
      }
    }
    .drawing-board-section {
      display: flex;
      flex-direction: column;
      gap: 16px;
      width: 100%;
      flex: 1;
      min-height: 0;
      border: 1px solid #05101f;
      border-radius: 15px;
      background: #19212c;
      padding: 8px 0;
    }

    .v-btn.render-btn {
      background: $admin-tertiary;
      color: white;
      font-weight: 700;
      font-size: 1rem;
      line-height: 1;
      padding: 20px;
      width: 100%;
      height: fit-content;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}
</style>
